import Planet from '../../shared/assets/Planet.svg'
import Star from '../../shared/assets/Star.svg'
import Storm from '../../shared/assets/Storm.svg'
import cls from './styles.module.scss'

function Home() {
    return (
        <div className={cls.home_container}>
            <div className={cls.title_block}>
                <img className={cls.planet} src={Planet} alt='' />
                <img className={cls.star} src={Star} alt='' />
                <img className={cls.storm} src={Storm} alt='' />
                <p className={cls.secondary_text}>Good afternoon,</p>
                <p className={cls.main_text}>Jacquie!</p>
            </div>
        </div>
    )
}

export default Home;