import { useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import './styles/index.scss';

function App() {

  const [theme, setTheme] = useState("light")

  return (
    <BrowserRouter>
      <Layout theme={theme} setTheme={setTheme} />
      <Routes>
        <Route index element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
