import React from 'react';
import Menu from '../../shared/icons/Menu.svg'
import Notification from '../../shared/icons/Notification.svg'
import cls from './styles.module.scss';

function Layout() {
    return (
        <div className={cls.layout_container}>
            <div className={cls.layout_block}>
                <div className={cls.menu}>
                    <img src={Menu} alt='' />
                </div>
                <p>Welcome</p>
                <div className={cls.notification}>
                    <img src={Notification} alt='' />
                </div>
            </div>
        </div>
    );
}

export default Layout;